var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"mb-5"},[_c('CCol',{attrs:{"lg":"4","xl":"3"}},[_c('TAside',{staticClass:"card"},[_c('CListGroup',[_c('CListGroupItem',[_c('CRow',[_c('CCol',{attrs:{"col":"12","lg":"12","xl":"6"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"From date","bold":""}}),_c('TInputDateTime',{key:_vm.startDateKey,staticClass:"mb-3",attrs:{"value":_vm.dateRange.start,"timeline":"start","placeholder":"Start date"},on:{"update:value":function($event){return _vm.$set(_vm.dateRange, "start", $event)}}})],1),_c('CCol',{attrs:{"col":"12","lg":"12","xl":"6"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"To date","bold":""}}),_c('TInputDateTime',{key:_vm.endDateKey,staticClass:"mb-3",attrs:{"value":_vm.dateRange.end,"timeline":"end","placeholder":"End date"},on:{"update:value":function($event){return _vm.$set(_vm.dateRange, "end", $event)}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('SSelectStatus',{attrs:{"value":_vm.status,"label":"Status","options":[
                  {
                    id: '',
                    name: this.$t('All'),
                  },
                  {
                    id: 'not_payment_due_date_yet_with_expected',
                    name: this.$t('Not payment due date yet'),
                  },
                  {
                    id: 'overdue_payment_with_expected',
                    name: this.$t('Payment overdue'),
                  },
                  {
                    id: 'payment_due_today_with_expected',
                    name: this.$t('Payment due date is today'),
                  },
                  {
                    id: 'expected_payment_due_date_empty',
                    name: this.$t('No payment due date'),
                  },
                  {
                    id: 'purchase_cost_outstanding',
                    name: this.$t('Payment not completed'),
                  } ]},on:{"update:value":function($event){_vm.status=$event}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('TInputText',{attrs:{"label":"Order Id","placeholder":"Order Id","value":_vm.filter['id'],"inputOptions":{ lazy: true }},on:{"update:value":function($event){return _vm.$set(_vm.filter, 'id', $event)},"input":function($event){_vm.filter['id'] = $event}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('SSelectCustomer',{attrs:{"label":"Customer","value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('SSelectContract',{attrs:{"label":"Contract","value":_vm.filter.contract_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "contract_id", $event)}}})],1)],1),_c('CRow',[_c('CCol',{staticClass:"d-flex mt-2",attrs:{"col":"12"}},[_c('TButton',{staticClass:"w-100",attrs:{"content":"Filter","disabled":_vm.loading || !_vm.dateRange.end},on:{"click":_vm.onFilter}}),_c('TButtonClear',{staticClass:"ml-1",attrs:{"variant":"outline","size":""},on:{"click":_vm.clearFilter}})],1)],1)],1),_vm._l((_vm.list),function(object,index){return _c('CListGroupItem',{key:("" + index),attrs:{"color":_vm.selected.customer_id_selected == object.customer_id
              ? 'primary'
              : 'secondary',"to":!_vm.loading
              ? _vm._.getAppendRouteQuery(_vm.$route, {
                  customer_id_selected: object.customer_id,
                })
              : ''}},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":object.customer ? object.customer.name : object.customer_id,"color":"primary","noTranslate":""}}),_c('TMessageMoney',{staticClass:"ml-auto",attrs:{"amount":object.purchase_cost_outstanding,"currency":"JPY"}})],1)])}),(_vm.loading)?_c('CListGroupItem',[_c('TSpinner',{attrs:{"loading":_vm.loading}})],1):_vm._e(),(!_vm.list.length && !_vm.loading)?_c('CListGroupItem',[_c('TMessageNotFound')],1):_vm._e(),(!_vm.loading && _vm.list.length)?_c('SButtonLoadmore',{staticClass:"mt-2 mb-3",attrs:{"store":"order.statistics_payment_due_date_users"}}):_vm._e()],2)],1)],1),(_vm.queryRoute.customer_id_selected)?_c('CCol',{attrs:{"lg":"8","xl":"9"}},[_c('div',{staticClass:"card p-3"},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('TMessage',{attrs:{"content":"Customer","bold":"","color":"primary"}}),(!_vm.lodash.isEmpty(_vm.detail))?_c('TMessage',{staticClass:"mb-2 h4",attrs:{"content":_vm.detail.customer ? _vm.detail.customer.name : _vm.detail.customer_id,"noTranslate":"","bold":""}}):_vm._e()],1),_c('CCol',{attrs:{"md":"6"}},[_c('TMessage',{attrs:{"content":"Purchase cost outstanding","bold":"","color":"primary"}}),_c('TMessageMoney',{staticClass:"h4",attrs:{"amount":_vm.detail.purchase_cost_outstanding,"currency":"JPY"}})],1)],1)],1),(_vm.fetchFirstTime)?_c('TTableAdvance',{attrs:{"items":_vm.tableList,"fields":_vm.fields,"store":"order.payment_orders","resource":"","noFilter":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"customer",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.customer ? item.customer.name : item.customer_id,"noTranslate":""}})],1)]}},{key:"contract_id",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.contract_id,"noTranslate":""}})],1)]}},{key:"payment_due_date",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.payment_due_date,"dateOnly":""}})],1)]}},{key:"id",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.id,"to":("/sale/orders/" + (item.type_id) + "/" + (item.id))}})],1)]}},{key:"shipping_fee_outstanding",fn:function(ref){
              var item = ref.item;
return [_c('td',_vm._l((item.lading_bills),function(lading_bill,index){return _c('TMessageMoney',{key:'shipping-fee-to-pay-' + index,attrs:{"amount":lading_bill.freight_charge_outstanding,"currency":lading_bill.currency_id}})}),1)]}},{key:"service_fee_outstanding",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_outstanding,"currency":item.service_fee_currency_id}})],1)]}},{key:"purchase_cost_outstanding",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_outstanding,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"shipping_fee",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},_vm._l((item.lading_bills),function(lading_bill,index){return _c('div',{key:'shipping-fee-' + index,staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":lading_bill.freight_charge_paid,"currency":lading_bill.currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":lading_bill.freight_charge,"currency":lading_bill.currency_id,"color":"muted"}})],1)}),0)]}},{key:"service_fee",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.service_fee_paid,"currency":item.service_fee_currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.service_fee,"currency":item.service_fee_currency_id,"color":"muted"}})],1)])]}},{key:"purchase_cost",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_paid,"currency":item.purchase_cost_currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.purchase_cost,"currency":item.purchase_cost_currency_id,"color":"muted"}})],1)])]}}],null,false,1412899341)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }